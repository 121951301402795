import Slide from "react-reveal/Slide"; // Importing Zoom effect
import './WorkXp.css';


function WorkXp(props) {

    return (
        <Slide right duration={2000}>
        <div id="work_body">
                <h2 id='work_title'>Work Experience & Certificates</h2>
                <div className="work_row">
                    <div className="work_column">
                        <ul className="ul_principles">
                        <li className="js_li work_li">
                                <img src="img/jppol.png" alt="jppol_logo" className="jppol_logo" />
                                JP/Politikens Hus
                                <p className="work_code_no_ani">{"Fullstack Developer | 1/02/2023 - Present"}</p>
                            </li>
                            <li className="js_li work_li">
                                <img src="img/jp_logo.png" alt="jp_logo" className="work_logo" />
                                Jyllands-Posten
                                <p className="work_code_no_ani">{"Web-Developer Intern | 10/08/2022 - 23/12/2022"}</p>
                            </li>
                            <li className="js_li work_li">
                                <img src="img/eaaa.png" alt="jp_logo" className="work_logo" />
                                Business Acadamy Aarhus
                                <p className="work_code_no_ani">{"AP Graduate in Computer Science | 17/12/2022"}</p>
                            </li>
                            <li className="js_li work_li">
                                <img src="img/rosborg_logo.jpg" alt="jp_logo" className="work_logo" />
                                Rosborg Gymnasium & HF
                                <p className="work_code_no_ani">{"Higher General Examination (STX) | 23/06/2017"}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Slide>
    );
}

export default WorkXp;