import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Components/Chat/Auth/Login";
import SignUp from "./Components/Chat/Auth/SignUp";
import Chat from "./Components/Chat/Chat";
import { auth } from "./Components/Chat/chat_firebase";
import Contact from "./Components/Contact/Contact";
import Projects from "./Components/Projects/Projects";
import Skills from "./Components/Skills/Skills";
import Welcome from "./Components/Welcome/Welcome";
import WorkXp from "./Components/WorkXp/WorkXp";

function App() {
  const [User] = useAuthState(auth);
  const [displayName, setdisplayName] = useState("");
  const [photoURL, setphotoURL] = useState("");


  useEffect(() => {
    User ? setdisplayName(User.displayName) : setdisplayName("");
    User ? setphotoURL(User.photoURL) : setphotoURL("");
  }, [User])
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Welcome />
                <Projects />
                <Skills />
                <WorkXp />
                <Contact />
              </div>
            }
          />

          <Route
            path="/chat"
            element={
              <Chat
                User={User}
                displayName={displayName}
                setdisplayName={setdisplayName}
                photoURL={photoURL}
                setphotoURL={setphotoURL}
              />
            }
          />
          <Route
            path="/chat/login"
            element={
              <Login
                User={User}
                displayName={displayName}
                setdisplayName={setdisplayName}
                photoURL={photoURL}
                setphotoURL={setphotoURL}
              />
            }
          />
          <Route
            path="/chat/signup"
            element={
              <SignUp
                User={User}
                displayName={displayName}
                setdisplayName={setdisplayName}
                photoURL={photoURL}
                setphotoURL={setphotoURL}
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
