import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import {
  getAuth,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { ref, set, getDatabase } from "firebase/database";
import Filter from "bad-words";
const filter = new Filter();
filter.addWords(
  ...[
    "fisse",
    "pik",
    "røvhul",
    "skede",
    "lort",
    "luder",
    "fede svin",
    "perker",
    "lorte",
  ]
);

const firebaseConfig = {
  apiKey: "AIzaSyCTb8VMxIIt_j7kENugk6z4Os0FXfaogx0",
  authDomain: "skulltoss-d67d4.firebaseapp.com",
  projectId: "skulltoss-d67d4",
  storageBucket: "skulltoss-d67d4.appspot.com",
  messagingSenderId: "852074471637",
  appId: "1:852074471637:web:a6127e85b95d5ec4b3425c",
  databaseURL:
    "https://skulltoss-d67d4-default-rtdb.europe-west1.firebasedatabase.app/",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth();

//+=============UTILS==============+
function postGlobalMessage(Message, index) {
  Message.message = filter.clean(Message.message);

  const db_real = getDatabase();
  set(ref(db_real, "chats/global/" + index), {
    message: Message,
  }).catch((error) => {
    console.log(error.code);
  });
}

async function loginUser(User) {
  return signInWithEmailAndPassword(auth, User.email, User.password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user.email, " user - LOGGED IN");

      if (user !== undefined) {
        return { succes: true, user };
      }
    })
    .catch((error) => {
      console.log(error.code);
      return { succes: false, error: error.code };
    });
}

async function createUser(User) {
  return createUserWithEmailAndPassword(auth, User.email, User.password)
    .then(async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user.email, " user - succesfully created");

      await updateProfile(auth.currentUser, {
        displayName: User.username + "-" + User.usercolor,
        photoURL:
          "https://bouncymustard.com/wp-content/uploads/2021/06/1-Funny-Dogs-Wearing-Wigs.jpg",
      })
        .then(() => {
          console.log("Username saved: " + auth.currentUser.displayName);
        })
        .catch((error) => {
          console.log(error.code);
          return { succes: false, error: error.code };
        });

      if (user !== undefined) {
        return { succes: true, user };
      }
    })
    .catch((error) => {
      console.log(error.code);
      return { succes: false, error: error.code };
    });
}

const logout = () => {
  signOut(auth);
};

export { auth, db, loginUser, createUser, postGlobalMessage, logout };
