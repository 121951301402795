
var helloArr = [
    "Marhaba",
    "Grüß Gott",
    "Namaskar",
    "Zdraveite",
    "Hola",
    "Hafa adai",
    "Nǐ hǎo",
    "Dobar dan ",
    "God dag",
    "Hoi",
    "hyvää päivää",
    "Bonjour",
    "Dia dhuit",
    "Guten tag",
    "Hello",
    "Yasou",
    "Shalom",
    "Namaste",
    "Salve",
    "Konnichiwa",
    "Sveiki",
    "Moïen",
    "Bonġu",
    "Namastē",
    "Hallo",
    "Salam",
    "Cześć",
    "Olá",
    "Bună ziua",
    "Zdravstvuyte",
    "Zdravo",
    "Ahoj",
    "Hola",
    "Hujambo",
    "Hallå",
    "Sawasdee",
    "Merhaba",
    "Hej",
    "Zdravstvuyte",
    "xin chào",
    "Shwmae",
    "Sawubona"
];

export default helloArr;