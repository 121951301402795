import "animate.css";
import "charts.css";
import React, { useState } from "react";
import Slide from "react-reveal/Slide";
import Xp from "../Xp/Xp";
import "./Graph.css";
import "./Skills.css";
import { e_update_xp, ipFetch } from "./js/functions";

const graphData = [
  { imgSrc: "img/css.png", id: "td_60" },
  { imgSrc: "img/tailwind.png", id: "td_60" },
  { imgSrc: "img/nextjs.png", id: "td_70" },
  { imgSrc: "img/nodejs.png", id: "td_80" },
  { imgSrc: "img/react.png", id: "td_90" },
  { imgSrc: "img/ts.png", id: "td_90" },
  { imgSrc: "img/js.png", id: "td_90" },
  { imgSrc: "img/csharp.png", id: "td_80" },
  { imgSrc: "img/java.png", id: "td_60" },
  { imgSrc: "img/git.png", id: "td_60" },
  { imgSrc: "img/mssql.png", id: "td_40" },
];

const xpData = [
  {
    name: "Javascript",
    imgSrc: "img/js.png",
    data: "Javascript",
    size: "md"
  },
  { imgSrc: "img/ts.png", data: "Typescript", name: "Typescript", size: "md" },
  { imgSrc: "img/html.png", data: "HTML", name: "HTML", size: "md" },
  { imgSrc: "img/css.png", data: "CSS", name: "CSS", size: "md" },
  { imgSrc: "img/tailwind.png", data: "Tailwind", name: "Tailwind", size: "md" },
  { imgSrc: "img/react.png", data: "React", name: "React", size: "md" },
  { imgSrc: "img/nextjs.png", data: "NextJs", name: "NextJs", size: "md" },
  { imgSrc: "img/nodejs.png", data: "NodeJs", name: "NodeJs", size: "md" },
  { imgSrc: "img/java.png", data: "Java", name: "Java", size: "md" },
  { imgSrc: "img/csharp.png", data: "Csharp", name: "C#", size: "sm" },
  { imgSrc: "img/mssql.png", data: "MSSQL", name: "MSSQL", size: "md" },
  { imgSrc: "img/git.png", data: "Git", name: "Git", size: "md" },
];

function Skills(props) {
  const [selected_xp, setXp] = useState("");
  const [ip, setIP] = useState("");

  return (
    <div id="body_100">
      <Slide left duration={2000}>
        <div id="xp_body">
          <h1 id="title">Skills</h1>
          <div id="xp_graph">
            <table
              className="charts-css column show-labels data-spacing-20 show-4-secondary-axes"
              id="chart"
            >
              <tbody>
                {graphData.map((x) => (
                  <tr>
                    <th scope="row">
                      <img
                        className="chart_label_img"
                        src={x.imgSrc}
                        alt={x.imgSrc}
                      />
                    </th>
                    <td
                      id={x.id}
                      onClick={(event) =>
                        e_update_xp(event, selected_xp, setXp)
                      }
                    ></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div id="container">
            <p id="container_title">&darr; pick one</p>
            {xpData.map((x) => (
              <>
                <div
                  className="item"
                  onClick={(event) => e_update_xp(event, selected_xp, setXp)}
                  data={x.data}
                  id={x.data}
                >
                  <img src={x.imgSrc} alt={x.imgSrc} className={x.size === "md" ? "item_img" : "item_img_small"} />
                  <p className="item_txt">{x.name}</p>
                </div>
              </>
            ))}
            <div
              className="item"
              onClick={(event) => {
                e_update_xp(event, selected_xp, setXp);
                ipFetch(setIP);
              }}
              data="Aboutyou"
              id="Aboutyou"
            >
              <img src={"img/anon.png"} alt="" className="item_img" />
              <p className="item_txt">About you</p>
            </div>
          </div>
        </div>
        <Xp selectedXp={selected_xp} ip={ip} />
      </Slide>
    </div>
  );
}

export default Skills;
