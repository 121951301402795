import React from "react";
import "./Xp.css";
import "animate.css";
import { makeMapUrl } from "./js/functions";

function Xp(props) {

  switch (props.selectedXp) {

    //____________JAVASCRIPT_________________________________
    case "Javascript":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Hoisting &rarr;
                  <p className="code">var count || let count || const count </p>
                </li>
                <li className="js_li">
                  Higher Functions &rarr;
                  <p className="code code2">
                    {"items.map( item => item.price )"}
                  </p>
                </li>
                <li className="js_li">
                  HTTP fetch &rarr;
                  <p className="code code2">
                    {'fetch("https://website.com/API")'}
                  </p>
                </li>
                <li className="js_li">
                  DOM &rarr;
                  <p className="code code4">
                    document.querySelector('#some_element')
                  </p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="/javascript_pure_project"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p className="project_note"> ImageViewer</p>
              </a>
              <p className="project_note">
                developed with pure Javascript-DOM [ALPHA-build]{" "}
              </p>
              <a
                href="/javascript_pure_project"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"img/pureJS.png"}
                  alt="jsimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );
    case "Typescript":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Declarative &rarr;
                  <p className="code code3_1">string | number | boolean</p>
                </li>
                <li className="js_li">
                  Type &rarr;
                  <p className="code code4_1">{"type Props = {isLoading: boolean, onChange: () => setValue()}"}</p>
                </li>
                <li className="js_li">
                  Casting &rarr;
                  <p className="code code3">{"Animal as Dog"}</p>
                </li>
                <li className="js_li">
                  Record &rarr;
                  <p className="code code4_1">{"const daEN: Record<string, string> = { hund: 'dog' }"}</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="https://search-page-jp.vercel.app/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p className="project_note">JP/Politikens Hus - Search Page <br /> (TSX)</p>
              </a>
              <a
                href="https://top100-movies.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={"img/jpsearch.png"}
                  alt="img/jpsearch.png"
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );
    case "HTML":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Node Tree Structure &rarr;
                  <p className="code code3">Parent || Child || Sibling </p>
                </li>
                <li className="js_li">
                  SEO-tagging &rarr;
                  <p className="code code4">
                    {'<meta property="og:type" content="website"/>'}
                  </p>
                </li>
                <li className="js_li">
                  Semantic Elements &rarr;
                  <p className="code code3">{"<header><figure><footer>"}</p>
                </li>
                <li className="js_li">
                  Tag Attributes &rarr;
                  <p className="code code3"> id=#myID || class=.myClass</p>
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="https://top100-movies.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p className="project_note">top100-movies.com</p>
              </a>
              <a
                href="https://top100-movies.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={"img/top100.png"}
                  alt="top100img"
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "CSS":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Media query &rarr;
                  <p className="code code4">
                    {"@media only screen and(max-width: 700px)"}
                  </p>
                </li>
                <li className="js_li">
                  Keyframes &rarr;
                  <p className="code code4">
                    {"@keyframes element {from {...} to {...}}"}
                  </p>
                </li>
                <li className="js_li">
                  Units &rarr;
                  <p className="code code2">Absolutes[px] || Relatives[vw]</p>
                </li>
                <li className="js_li">
                  Class & ID logic&rarr;
                  <p className="code code3">{".myClass{...} || #myID{...}"}</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <p className="project_note"> Responsive webdesign </p>
              <img src="img/iphone.png" alt="iphoneimg" id="iphone_icon" />
              <a
                href="https://top100-movies.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/top100mobile.png"
                  alt="top100img"
                  className="project_img __css_img"
                />
              </a>
              <a
                href="https://top100-movies.com/Action_Adventure"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/top100mobile02.png"
                  alt="top100img"
                  className="project_img __css_img"
                />
              </a>
              <a
                href="https://frederikbarasinski.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/fredemobile.png"
                  alt="fredimg"
                  className="project_img __css_img"
                />
              </a>
              <a
                href="https://frederikbarasinski.com/Collection/66%20North%20x%20Alex%20H%C3%B8gh"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/fredemobile02.png"
                  alt="fredimg"
                  className="project_img __css_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "Tailwind":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Classes &rarr;
                  <p className="code code2">{"<div class='flex justify-start'>"}</p>
                </li>
                <li className="js_li">
                  Theming &rarr;
                  <p className="code code4_1">{"module.exports = { theme: {colors: {'blue': '#my-cool-blue'}}}"}</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="https://search-page-jp.vercel.app/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p className="project_note">JP/Politikens Hus - Search Page <br /> (Styled with Tailwind)</p>
              </a>
              <a
                href="https://top100-movies.com/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <img
                  src={"img/jpsearch.png"}
                  alt="img/jpsearch.png"
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div >
      );

    case "React":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  ES6 Components &rarr;
                  <p className="code code3_2">{"React.Component{...}"}</p>
                </li>
                <li className="js_li">
                  Function Components &rarr;
                  <p className="code code3_1">
                    {"function MyComponent(props){..}"}
                  </p>
                </li>
                <li className="js_li">
                  Hooks &rarr;
                  <p className="code code4">
                    {"const [count, setCount] = useState(0);"}
                  </p>
                </li>
                <li className="js_li">
                  Routing &rarr;
                  <p className="code code4_1">
                    {'<Route path="/about" element={ <About/> }>'}
                  </p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="#welcome_body"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p className="project_note"> OliverHB.com</p>
              </a>
              <p className="project_note">
                This website was developed with React
              </p>
              <a href="#welcome_body" rel="noreferrer">
                <img
                  src={"img/websiteScreenshot.png"}
                  alt="reactimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "NextJs":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Pre-rendering &rarr;
                  <p className="code">
                    {"Static Generation vs Server-side Rendering"}
                  </p>
                </li>
                <li className="js_li">
                  Routing &rarr;
                  <p className="code code3_2">{"about → pages/about.js"}</p>
                </li>
                <li className="js_li">
                  API &rarr;
                  <p className="code code4_2">
                    {"export default function handler(req, res) {...}"}
                  </p>
                </li>
                <li className="js_li">
                  Jest &rarr;
                  <p className="code code3_1">{"Component Test + JUnit"}</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="#welcome_body"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <p className="project_note"> Jyllands-Posten NEW search-page</p>
              </a>
              <p className="project_note">
                Computer Science examination project
              </p>
              <a
                href="https://search-page-jp.vercel.app/"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"img/jpsearch.png"}
                  alt="reactimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "NodeJs":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Modules</h2>
              <div id="div_frameworks">
                <p className="code_no_ani __frameworks">Express</p>
                <p className="code_no_ani __frameworks">Express-session</p>
                <p className="code_no_ani __frameworks">Particle</p>
                <p className="code_no_ani __frameworks">Mssql</p>
                <p className="code_no_ani __frameworks">Nodemon</p>
                <p className="code_no_ani __frameworks">Stripe</p>
                <p className="code_no_ani __frameworks">Fetch</p>
                <p className="code_no_ani __frameworks">Axios</p>
                <p className="code_no_ani __frameworks">Charts.css</p>
                <p className="code_no_ani __frameworks">Animate.css</p>
                <p className="code_no_ani __frameworks">React</p>
                <p className="code_no_ani __frameworks">Chai</p>
                <p className="code_no_ani __frameworks">Mocha</p>
                <p className="code_no_ani __frameworks">Firestore</p>
                <p className="code_no_ani __frameworks">Puppeteer</p>
                <p className="code_no_ani __frameworks">Leaflet</p>
                <p className="code_no_ani __frameworks">Nodemailer</p>
                <p className="code_no_ani __frameworks">Pug</p>
                <p className="code_no_ani __frameworks">Dotenv</p>
                <p className="code_no_ani __frameworks">Gcloud</p>
                <p className="code_no_ani __frameworks">React-Query</p>
                <p className="code_no_ani __frameworks">Cypress</p>
                <p className="code_no_ani __frameworks">Tanstack</p>
                <p className="code_no_ani __frameworks">React-Router</p>
              </div>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <a
                href="https://frederikbarasinski.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <p className="project_note">
                  frederikbarasinski.com <br /> (all my sites are run on
                  Express.js server)
                </p>
              </a>
              <a
                href="https://frederikbarasinski.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"img/fred.png"}
                  alt="nodeimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "Java":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  MVC-development &rarr;
                  <p className="code code3">Model||View||Controller</p>
                </li>
                <li className="js_li">
                  Declarative &rarr;
                  <p className="code code2">Integer||String||Boolean||Double</p>
                </li>
                <li className="js_li">
                  JUnit &rarr;
                  <p className="code code3">assertEquals()||assertTrue()</p>
                </li>
                <li className="js_li">
                  JavaFX &rarr;
                  <p className="code code3">App.launch(App.class);</p>
                </li>
                <li className="js_li">
                  Design Patterns &rarr;
                  <p className="code code3"> Observer|Singleton|Iterator</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <p className="project_note">Aarhus Local Beer Brewery</p>
              <p className="project_note">Sales and Storage management demo</p>
              <a href="/java_demo" target="_blank" rel="noreferrer">
                <img
                  src={"img/java_project.png"}
                  alt="javaimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>

          </div>
        </div>
      );

    case "Csharp":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  WPF &rarr;
                  <p className="code 4">{"Desktop Client Application UI"}</p>
                </li>
                <li className="js_li">
                  LINQ &rarr;
                  <p className="code code4">
                    {" from score in scores where score > 80 select score;"}
                  </p>
                </li>
                <li className="js_li">
                  ASP.NET &rarr;
                  <p className="code code4_2">
                    {"API, Razor, Entity Framework, Client, MVC"}
                  </p>
                </li>
                <li className="js_li">
                  Basic OOP &rarr;
                  <p className="code code3">
                    {"Interfaces, Polymorphismm, Types etc."}
                  </p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <p className="project_note">
                WPF Car app + ASP.NET Employee app{" "}
              </p>
              <p className="project_note">(none are live sorry.)</p>
            </div>
          </div>
        </div>
      );

    case "MSSQL":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Transactions &rarr;
                  <p className="code code4">
                    BEGIN TRANSACTION; DELETE FROM ... WHERE ..;{" "}
                  </p>
                </li>
                <li className="js_li">
                  Constraints &rarr;
                  <p className="code code4">
                    Primary/Foreign Keys || Checks || Uniques
                  </p>
                </li>
                <li className="js_li">
                  Aggregates &rarr;
                  <p className="code code2">COUNT||MAX||MIN||SUM||AVG</p>
                </li>
                <li className="js_li">
                  Joins &rarr;
                  <p className="code code3">Full join||Left join||Union</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <p className="project_note"> top100-movies.com</p>
              <p className="project_note">
                Movie data is stored on a local SQL server
              </p>
              <a
                href="https://top100-movies.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"img/top100.png"}
                  alt="mssqlimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "Git":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">Principles</h2>
              <ul id="ul_principles">
                <li className="js_li">
                  Forking &rarr;
                  <p className="code code4">
                    git clone https://github.com/OliverHB999/React.git
                  </p>
                </li>
                <li className="js_li">
                  Remote Repository &rarr;
                  <p className="code code2">git remote add myRemote /url</p>
                </li>
                <li className="js_li">
                  push/pull/commit &rarr;
                  <p className="code code3">git push -u origin master</p>
                </li>
                <li className="js_li">
                  Branching &rarr;
                  <p className="code code3"> git checkout -b myBranch</p>
                </li>
                <li className="js_li">
                  And much more...
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Projects</h2>
              <p className="project_note"> www.github.com/OliverHB999</p>
              <p className="project_note">My github</p>
              <a
                href="https://github.com/OliverHB999/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"img/olivergit.png"}
                  alt="mssqlimg"
                  srcSet=""
                  className="project_img"
                />
              </a>
            </div>
          </div>
        </div>
      );

    case "Projects":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">{props.selectedXp}</h2>
          <div className="row">
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">&darr;</h2>
              <a
                href="https://top100-movies.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <p className="project_note">top100-movies.com</p>
                <img src="img/top100.png" alt="" className="project_img" />
              </a>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">&darr;</h2>
              <a
                href="https://frederikbarasinski.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <p className="project_note">frederikbarasinski.com</p>
                <img src="img/fred.png" alt="" className="project_img" />
              </a>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">&darr;</h2>
              <a
                href="https://search-page-jp.vercel.app/"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <p className="project_note">Jyllands-Posten NEW search-page</p>
                <img src="img/jpsearch.png" alt="" className="project_img" />
              </a>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">&darr;</h2>
              <p className="project_note">ammishbutterchicken.dk</p>
              <img
                src="img/amish.png"
                alt=""
                className="project_img"
                style={{ opacity: "0.4", cursor: "auto" }}
              />
              <p
                className="project_note"
                id="in_dev_p"
                style={{ marginTop: "25vh" }}
              >
                {"< Still in development />"}
              </p>
            </div>
          </div>
        </div>
      );

    case "Aboutyou":
      return (
        <div className="selectedXp_body" key={props.selectedXp}>
          <h2 id="sXP_title">About you</h2>
          <div className="row">
            <div className="column">
              <h2 className="js_title js_title_centered">
                Your device properties
              </h2>
              <ul id="ul_principles">
                <li className="js_li">
                  IP-Address &rarr;
                  <p className="code_no_ani">{props.ip.ip}</p>
                </li>
                <li className="js_li">
                  Country &rarr;
                  <p className="code_no_ani">{props.ip.country}</p>
                </li>
                <li className="js_li">
                  City &rarr;
                  <p className="code_no_ani">{props.ip.city}</p>
                </li>
                <li className="js_li">
                  Zip &rarr;
                  <p className="code_no_ani">{props.ip.zip}</p>
                </li>
                <li className="js_li">
                  ISP &rarr;
                  <p className="code_no_ani">{props.ip.isp}</p>
                </li>
              </ul>
            </div>
            <div className="column column_centered">
              <h2 className="js_title js_title_centered">Your location</h2>
              <p className="project_note">
                {props.ip.country} - {props.ip.city}
              </p>
              <p className="project_note">
                {props.ip.lat + "," + props.ip.lon}
              </p>
              <img
                src={makeMapUrl(props.ip.lat, props.ip.lon)}
                alt="location"
                srcSet=""
                className="project_img"
              />
            </div>
          </div>
        </div>
      );
    //_____________DEFAULT____________________________________
    default:
      return <div></div>;
  }
}

export default Xp;
