import React from "react";
import Header from "../Chat/Header/Header";
import "./Chat.css";
import { Message } from "./Models/Message";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { postGlobalMessage } from "../Chat/chat_firebase";

export default function Chat(props) {
  const [Messages, setMessages] = useState([]);

  useEffect(() => {
    if (props.User) {
      const db = getDatabase();
      const global = ref(db, "chats/global/");
      const realtimeReads = onValue(global, (snapshot) => {
        //mount
        const data = snapshot.val();
        if (data !== null) {
          setMessages(data);
        }
      });

      return () => {
        //dismount

        realtimeReads();
      };
    }
  }, [props.User]);

  useEffect(() => {
    let messages_div = document.getElementsByClassName("messages_div")[0];
    if (messages_div != null)
      messages_div.scrollTop = messages_div.scrollHeight;
    return () => {
      let messages_div = document.getElementsByClassName("messages_div")[0];
      if (messages_div != null)
        messages_div.scrollTop = messages_div.scrollHeight;
    };
  }, [Messages]);

  const send = () => {
    let input = document.querySelector(".input_chat").value;

    if (input.length > 0) {
      postGlobalMessage(
        new Message(
          input,
          props.User.displayName,
          new Date().toUTCString()
        ).toJSON(),
        Messages.length
      );
      document.querySelector(".input_chat").value = "";

      let messages_div = document.getElementsByClassName("messages_div")[0];
      messages_div.scrollTop = messages_div.scrollHeight;
    }
  };

  const input_enter = (e) => {
    if (e.key === "Enter") {
      send();
    }

  };

  const convertTimeStamp = (stamp) => {
    const curret = new Date();
    const messageDate = new Date(stamp);

    if (curret.toDateString() === messageDate.toDateString()) {
      return messageDate.getHours() + ":" + messageDate.getMinutes();
    } else {
      return messageDate.toLocaleDateString();
    }
  };

  return (
    <div>
      <Header
        User={props.User}
        displayName={props.displayName}
        setdisplayName={props.setdisplayName}
        photoURL={props.photoURL}
        setphotoURL={props.setphotoURL}
      ></Header>
      {props.User ? (
        <div className="chat_body">
          <h1>CHAT</h1>
          <div id="chat">
            <div className="section_div">
              <div className="messages_div">
                {Messages.length > 0 ? (
                  Messages.map((ele, index) => {
                    return (
                      <div className="message" key={index}>
                        <p className="message_stamp">
                          {convertTimeStamp(ele.message.timestamp)}
                        </p>
                        <p className="username_chat" style={{color: ele.message.user.split("-")[1]}}>{ele.message.user.split("-")[0]}: </p>
                        <p className="user_message">{ele.message.message}</p>
                      </div>
                    );
                  })
                ) : (
                  <p style={{textAlign: "center"}}>Chat is empty</p>
                )}
              </div>
              <div className="input_div">
                <input
                  type="text"
                  className="input_chat"
                  onKeyDown={input_enter}
                />
                <button onClick={send} className="chat_send_btn">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="please_login_div">
            <img src={"/img/warning.png"} alt="" />
            <h1 className="lobby_title">Please login to access Chat</h1>
            <Link to={"/chat/login"} className="lobby_login_link">
              Login here
            </Link>
            <br />
            <br />
            <h3 className="lobby_title">Don't have an account yet?</h3>
            <Link to={"/chat/signup"} className="lobby_login_link">
              Sign up here
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
