
//CSS in main App.css for global scope

export function PopUp(message, status){

    const body = document.querySelector('body');

    const modal = document.createElement('div');
    modal.className = 'popup_modal'

    const popupDiv = document.createElement('div');
    popupDiv.className = 'popup_div'


    const p = document.createElement('p')
    p.className = 'popup_p'
    p.textContent = message;

    const button = document.createElement('button')
    button.className = 'popup_btn'
    button.id = 'popup_btn'
    button.innerHTML = 'Close'
    button.autofocus = true
    button.tabIndex = -1
    button.onclick = () =>{
        body.removeChild(modal);
    }

    button.onload = () => {
        console.log("loaded")
        button.focus()
    }

    popupDiv.appendChild(p)
    popupDiv.appendChild(button)

    modal.appendChild(popupDiv)

    body.appendChild(modal)

    document.getElementById("popup_btn").focus();
}