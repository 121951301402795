import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import "../Header/Header.css";
import { PopUp } from "../Popup/popup";
import { loginUser } from "../chat_firebase";
import "./AuthGlobals.css";

export default function Login(props) {
  let navigate = useNavigate();

  useEffect(() => {
    if (props.User) navigate("/chat", { replace: true });
  }, [props.User]);

  async function requestLogin(event) {
    event.preventDefault();

    let email = document.querySelector("#login_email").value;
    let password = document.querySelector("#login_pass").value;

    let user = { email: email, password: password };

    let response = await loginUser(user);

    if (!response.succes) {
      PopUp(response.error, "error");
    } else {
      PopUp("Welcome " + response.user.displayName.split("-")[0], "ok");
      props.setdisplayName(response.user.displayName);
      props.setphotoURL(response.user.photoURL);
      navigate("/chat", { replace: true });
    }
  }

  return (
    <div>
      <Header
        User={props.User}
        displayName={props.displayName}
        setdisplayName={props.setdisplayName}
        photoURL={props.photoURL}
        setphotoURL={props.setphotoURL}
      ></Header>

      <div class="grid align__item">
        <div class="register">
          <h2>Login</h2>

          <form action="#" onSubmit={requestLogin} method="post" class="form">
            <div class="form__field">
              <input
                type="email"
                placeholder="Email"
                id="login_email"
                className="auth_input"
              />
            </div>

            <div class="form__field">
              <input
                type="password"
                minLength={6}
                placeholder="Password"
                id="login_pass"
                className="auth_input"
              />
            </div>

            <div class="form__field">
              <input
                type="submit"
                value="Log in"
                className="submit_auth_form"
              />
            </div>
          </form>

          <p>
            Don't have an account yet?{" "}
            <Link to={"/chat/signup"} style={{ color: "orange" }}>
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
