import React from "react";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "./Projects.css";

export default function Projects() {
  return (
    <div id="projects_body">
      <Fade duration={4000}>
      <h1 className="projects_title title">Projects and Demos</h1>
      <div className="projects_content">
        {[
          {
            img: "img/fred.png",
            title: "frederikbarasinski.com",
            desc: "Portfolio Photographer & Content Creator",
            url: "https://frederikbarasinski.com/",
          },
          {
            img: "img/jpsearch.png",
            title: "JP/Politikens Hus - Search Page",
            desc: "Computer Science Final Exam Project",
            url: "https://search-page-jp.vercel.app/",
          },
          {
            img: "img/ChatUserDemo.png",
            title: "Chat & User Demo",
            desc: "Firebase User Realtime Chat App",
            url: "/chat",
          },
          {
            img: "img/top100.png",
            title: "top100-movies.com",
            desc: "Webscraping App",
            url: "https://top100-movies.com/",
          },
        ].map((ele) => {
          if(!ele.url.includes("https")){
            return (
              <div className="pro_col" key={ele.url}>
                <Link
                  to={ele.url}
                  style={{ textDecoration: "none" }}
                  className="a_project"
                >
                  <p className="projects_demo_p"> {ele.title} </p>
                  <p className="projects_demo_p"> {ele.desc} </p>
                  <img src={ele.img} alt="" className="project_demos_img" />
                </Link>
              </div>
            );
          }else{
          return (
            <div className="pro_col" key={ele.url}>
              <a
                href={ele.url}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
                className="a_project"
              >
                {" "}
                <p className="projects_demo_p"> {ele.title} </p>
                <p className="projects_demo_p"> {ele.desc} </p>
                <img src={ele.img} alt="" className="project_demos_img" />
              </a>
            </div>
          );
          }
        })}
      </div>
      </Fade>
    </div>
  );
}
