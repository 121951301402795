import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { logout } from "../chat_firebase";
import "./Header.css";

export default function Header(props) {

  useEffect(() => {

    if (props.photoURL) {
      let photo = document.getElementsByClassName("profile_img")[0]
      photo.src = props.photoURL

    }

    if (props.displayName) {
      let username = document.getElementsByClassName("username")[0]
      username.textContent = props.displayName.split("-")[0]
      username.style.color = props.displayName.split("-")[1]
    }

  }, [props.photoURL, props.displayName])


  if (props.User) {
    return (
      <div id="header-body">
        <div className="header-content">
          <Link to={"/"} className="no_deco login_link menu">
            Menu
          </Link>
          <button
            onClick={() => {
              logout();
              props.setdisplayName("")
              props.setphotoURL("")
            }}
            className="no_deco login_link"
          >
            Log out
          </button>
          <div className="profileDiv">
            <img
              className="profile_img"
              alt="profile img"
              src="img/userdefault.png"
            ></img>
            <p className="username">user</p>
          </div>
        </div>

        <hr className="line"></hr>
      </div>
    );
  } else {
    return (
      <div id="header-body">
        <div className="header-content">
          <Link to={"/"} className="no_deco login_link menu">
            Menu
          </Link>
          <Link to={"/chat/signup"} className="no_deco login_link">
            Sign Up
          </Link>
          <Link to={"/chat/login"} className="no_deco login_link">
            Login
          </Link>
        </div>
        <hr className="line"></hr>
      </div>
    );
  }
}
