import axios from "axios";

export function ipFetch(setIP) {
  let data = {
    ip: "No data",
    city: "No data",
    country: "No data",
    zip: "No data",
    isp: "No data",
    lat: "",
    lon: "",
  };

  axios
    .get("https://ipapi.co/json/")
    .then(
      (res) =>
        (data = {
          ip: res.data.ip,
          city: res.data.city,
          country: res.data.country_name,
          zip: res.data.postal,
          isp: res.data.org,
          lat: "" + res.data.latitude,
          lon: "" + res.data.longitude,
        })
    )
    .catch(() =>
      alert(
        "About you fetch was blocked by an external system or network - no data was retrived"
      )
    )
    .finally(() => setIP(data));
}

export function e_update_xp(event, selected_xp, setXp) {
  const xp_name = event.target.attributes.data.value;

  const xp_target = document.querySelector(`#${xp_name}`);
  const xp_body = document.querySelector("#xp_body");

  if (selected_xp === "") {
    xp_target.style.transition = "ease-out 1s";
    xp_target.style.transform = "scale(1.05)";
    xp_target.style.backgroundColor = "var(--ligther-grey)";

    xp_body.style.transition = "ease-out 1s";
    xp_body.style.borderBottom = "none";

    setXp(xp_name);
  } else if (xp_name !== selected_xp && selected_xp !== "") {
    const prevState = document.querySelector(`#${selected_xp}`);

    prevState.style.transition = "ease-out 1s";
    prevState.style.transform = "scale(1.0)";
    prevState.style.backgroundColor = "";

    xp_target.style.transition = "ease-out 1s";
    xp_target.style.transform = "scale(1.05)";
    xp_target.style.backgroundColor = "var(--ligther-grey)";

    setXp(xp_name);
  } else {
    xp_target.style.transition = "ease-out 1s";
    xp_target.style.transform = "scale(1.0)";
    xp_target.style.backgroundColor = "";

    xp_body.style.transition = "ease-out 1s";
    xp_body.style.borderBottom = "1px solid rgb(80, 80, 80)";

    setXp("");
  }
}
