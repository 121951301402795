export class Message{
    constructor(message, user, timestamp){
      this.message = message;
      this.user = user;
      this.timestamp = timestamp;
    }

    toJSON(){
      return {
        message: this.message,
        user: this.user,
        timestamp: this.timestamp
      }
    }
  }