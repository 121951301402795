
export default function mailHandler(name, email, text){

    let mailData = {
        name: name,
        email: email,
        message: text
    };

    const in_name = document.querySelector('#in_name');
    const in_email = document.querySelector('#in_email');
    const in_text = document.querySelector('#in_text');

    (async () => {
        const rawResponse = await fetch('/send-contact-form', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(mailData)
        });

        const response = await rawResponse.json();

        if(response.succes === true){
            alert("Email succesfully sent! I will get back to you as soon as possible. - Oliver HB")
        }else{
            alert("Error:" + response.res.response)
        }
        in_name.value = ''
        in_email.value = ''
        in_text.value = ''
      })();
};