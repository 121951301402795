import React from "react";
import './Welcome.css'
import Particles from "react-tsparticles";
import 'animate.css';
import helloArr from './hello.js'


function Welcome(props) {

  const e_handler_click = (e) => {
    const img_txt = document.querySelector('.img_txt')
    let rand = Math.floor(Math.random() * 39);
    img_txt.style.color = "var(--white)";
    img_txt.innerHTML = helloArr[rand]
    img_txt.style.transition = "ease-in 1s";
    img_txt.style.opacity = 1
  }

  const particlesInit = (main) => {
    //console.log(main);
  };

  const particlesLoaded = (container) => {
    //console.log(container);
  };

  const e_handler_On = (e) => {
    const img = document.querySelector('#img');
    const txt = document.querySelectorAll('.welcome_txt')
    const theTag = [...document.querySelectorAll('.theTag')]

    txt.forEach(ele => {
      ele.style.transition = "ease-in 1s";
      ele.style.opacity = "1";
    })

    theTag.forEach(ele => {
      ele.style.transition = "ease-in 1s";
      ele.style.opacity = "1";
    })

    theTag[0].style.animation = "bounceInLeft";
    theTag[0].style.animationDuration = "1s";

    theTag[1].style.animation = "bounceInRight";
    theTag[1].style.animationDuration = "1s";

    img.style.transition = "ease-in 1s";
    img.style.filter = "saturate(120%)";

  }

  const e_handler_Off = (e) => {
    const img = document.querySelector('#img');
    const txt = document.querySelectorAll('.welcome_txt')
    const theTag = document.querySelectorAll('.theTag')
    const img_txt = document.querySelector('.img_txt')

    txt.forEach(ele => {
      ele.style.transition = "ease-out 1s";
      ele.style.opacity = "0.7";
    })

    theTag.forEach(ele => {
      ele.style.transition = "ease-out 1s";
      ele.style.opacity = "0";
    })

    theTag[0].style.animation = "fadeOutDown";
    theTag[0].style.animationDuration = "1s";

    theTag[1].style.animation = "fadeOutDown";
    theTag[1].style.animationDuration = "1s";

    img_txt.style.transition = "ease-out 1s";
    img_txt.style.opacity = 0

    img.style.transition = "ease-out 1s";
    img.style.filter = "saturate(90%)";
  }


  return (
    <div id="welcome_body">
      <div id="clickDiv">
        <img src={"img/click.png"} id="clickMe" alt="" srcSet="" />
        <p id="clickP">click</p>
      </div>
      <h2 className="welcome_txt" id="txt" onMouseOver={e_handler_On} onMouseOut={e_handler_Off}>Oliver HB</h2>
      <h2 className="welcome_txt welcome_txt_2" onMouseOver={e_handler_On} onMouseOut={e_handler_Off}>
        <h1 className="theTag">&#60;</h1>Fullstack developer<h1 className="theTag" id="theTag2">/&#62;</h1>
      </h2>
      <img src={"img/oliver3.JPG"} id="img" alt="" onMouseOver={e_handler_On} onMouseOut={e_handler_Off} onClick={e_handler_click} />

      <div className="img_div_text">
        <p className="img_txt"></p>
      </div>


      <div id="modal"></div>

      <Particles key={"key"}
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#111111",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 1,
              },
              repulse: {
                distance: 200,
                duration: 0.1,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 2,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: true,
              speed: 0.5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />

    </div>
  );


}

export default Welcome;