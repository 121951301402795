import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import "../Header/Header.css";
import { PopUp } from "../Popup/popup";
import { createUser } from "../chat_firebase";
import "./AuthGlobals.css";

export default function SignUp(props) {
  let navigate = useNavigate();

  useEffect(() => {
    if (props.User) navigate("/chat", { replace: true });
  }, [props.User]);

  async function requestSignUp(event) {
    event.preventDefault();

    let email = document.querySelector("#email").value;
    let username = document.querySelector("#username").value;
    let password = document.querySelector("#pass").value;
    let usercolor = document.querySelector("#usercolor").value;

    let user = {
      email: email,
      username: username,
      password: password,
      usercolor: usercolor,
    };

      let response = await createUser(user);

      if(!response.succes){
        PopUp(response.error, "error")
      }else{
        PopUp("Welcome " + response.user.displayName.split("-")[0] , "ok")
        props.setdisplayName(response.user.displayName)
        props.setphotoURL(response.user.photoURL)
    }
  }

  return (
    <div>
     <Header
        User={props.User}
        displayName={props.displayName}
        setdisplayName={props.setdisplayName}
        photoURL={props.photoURL}
        setphotoURL={props.setphotoURL}
      ></Header>
      <div class="grid align__item">
        <div class="register">
          <h2>Sign Up</h2>

          <form action="#" onSubmit={requestSignUp} method="post" class="form">
            <div class="form__field">
              <input
                type={"text"}
                minLength={5}
                maxLength={20}
                placeholder="Username"
                id="username"
                className="auth_input"
              />
            </div>

            <div class="form__field">
              <input
                type="email"
                placeholder="Email"
                id="email"
                className="auth_input"
              />
            </div>

            <div class="form__field">
              <input
                type="password"
                minLength={6}
                placeholder="Password"
                id="pass"
                className="auth_input"
              />
            </div>

            <div class="form__field">
              <p>&darr; pick your color &darr;</p>
              <input
                type="color"
                id="usercolor"
                defaultValue="#ffa500"
                className="submit_auth_form"
              />
            </div>

            <div class="form__field">
              <input
                type="submit"
                value="Sign up"
                className="submit_auth_form"
              />
            </div>
          </form>

          <p>Already have an account? <Link to={"/chat/login"} style={{color: "orange"}}>Log in</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
